@use '@angular/material' as mat;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');
@import "node_modules/bootstrap/scss/bootstrap";
@import "themes/colors";
@import "themes/typography";

@include mat.core();

$min-container-height: calc(100vh - 64px);
$min-container-height-mobile: calc(100vh - 54px);
$min-container-height-no-toolbar: calc(100vh - 10px);
$min-container-height-no-toolbar-mobile: calc(100vh);

html, body {
  //height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.full-screen-height {
  //height: 100%;
  min-height: calc(100vh - 64px);
  //max-height: 100vh;
}

.full-screen-height-page-banner {
  height: 100%;
  min-height: calc(100vh - 64px - 154px);
  //max-height: 100vh;
}

.full-screen-height-page-default {
  height: 100%;
  min-height: calc(100vh - 64px - 136px);
  //max-height: 100vh;
}

.btn-cursor {
  cursor: pointer;
}

.page-padding {
  padding-left: 20px;
  padding-right: 15px;
}

.page-content {
  width: 100%;
  background-color: $surface-light;
  padding-top: 20px;
  padding-bottom: 15px;
}

.mat-divider {
  border-top: 2px solid $border-grey !important;
}

.mat-divider.mat-divider-vertical {
  border-right: 2px solid $border-grey !important;
}

// page-tabs
.menu-vertical-divider {
  border-left: 3px solid $outline-on-surface-light;
  height: inherit;
}

.nav-tab-bar {
  display: flex;
  flex-direction: row;

  span {
    @extend .body-1-regular-2;
  }


  mat-icon {
    color: $icon-grey;
  }
}

// dialogs

.dialog-backdrop {
  background-color: transparent;
}

.dialog-backdrop-opaque {
  background-color: $disabled-dark;
  opacity: 0.9 !important;
}

.dialog-small {
  min-height: 50px;
  max-height: 300px;
  width: 180px;

  .mat-mdc-dialog-container .mdc-dialog__surface {
    box-shadow: none !important;
  }

  .mat-mdc-dialog-surface {
    border-radius: 5px !important;
  }
}

.dialog-medium {
  min-height: 300px;
  width: 202px;
  display: flex !important;
  flex-direction: column;

  .mat-mdc-dialog-container .mdc-dialog__surface {
    box-shadow: 0 4px 7px rgba(#000000, 0.16) !important;
    display: flex !important;
    flex-grow: 1 !important;
  }

  app-notifications-dialog {
    display: flex !important;
    flex-grow: 1 !important;
  }

  .mdc-dialog__surface {
    display: flex !important;
    flex-grow: 1 !important;
  }

  .mat-mdc-dialog-surface {
    border-radius: 12px !important;
    flex-grow: 1 !important;
  }
}

.dialog-large {
  min-height: 300px;
  width: 404px;

  .mat-mdc-dialog-container .mdc-dialog__surface {
    box-shadow: 0 4px 7px rgba(#000000, 0.16) !important;
  }

  .mat-mdc-dialog-surface {
    border-radius: 12px !important;
  }
}

.dialog-large-half-screen {
  min-height: 75vh !important;
  width: 100% !important;
  max-width: 100vw !important;
  position: absolute !important;
  bottom: 0px !important;

  @extend .dialog-large;

  .cdk-overlay-pane {
    max-width: 100vw !important;
  }
}

.remove-panel {
  min-height: 50px;
  max-height: 300px;
  width: 220px;
  border-radius: 5px !important;
  background-color: $panel-grey;
}

// notification tile

.notification-tile-unread, .notification-tile-read, .notification-tile-warn {
  min-width: 180px;
  min-height: 40px;
  border-radius: 5px !important;
  padding: 7px
}

.notification-tile-unread {
  background-color: $drawer-light;

  .notification-title {
    @extend .footer-default;
  }

  .notification-time {
    @extend .body-5-default;
  }

  .notification-text {
    @extend .body-4-default;
  }
}

.notification-tile-read {
  background-color: $outline-on-surface-light;

  .notification-title {
    @extend .footer-default;
  }

  .notification-time {
    @extend .body-5-default;
  }

  .notification-text {
    @extend .body-4-default;
  }
}

.notification-tile-warn {
  background-color: $warn-color;

  .notification-title {
    @extend .footer-active;
  }

  .notification-time {
    @extend .body-5-active;
  }

  .notification-text {
    @extend .body-4-active;
  }

  .mat-icon {
    color: $drawer-light;
  }
}

// text field
.no-underline-text-field {
  .mdc-line-ripple::before {
    border-bottom-width: 0 !important;
  }
  .mdc-line-ripple::after {
    border-bottom-width: 0 !important;
  }
}

.custom-text-field {
  height: 33px;
  width: 200px;

  .mdc-text-field {
    padding: 0 !important;
  }

  .mat-mdc-text-field-wrapper, .mat-mdc-form-field-infix {
    min-height: 33px;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field .mdc-notched-outline__notch {
    width: fit-content !important;
  }

  .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    max-width: none !important;
  }

  .mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    transform: translateY(-25px) scale(1) !important;
    @extend .body-1-default;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: calc(var(--mat-form-field-container-height)/3.2)!important;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    height: 33px !important;
    text-overflow: ellipsis;
  }

  .mat-mdc-text-field-wrapper {
    height: 33px !important;
  }

  .mat-mdc-form-field-subscript-wrapper {
    height: 10px;
  }

  .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
    padding: 0 !important;
  }

  .mat-mdc-form-field-bottom-align::before {
    height: 10px;
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
    @extend .body-1-regular-1;
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    @extend .body-1-regular-1;
  }

  .mdc-text-field--disabled .mdc-floating-label {
    @extend .body-1-regular-1;
  }

  .mdc-text-field--disabled .mdc-text-field__input {
    @extend .body-1-regular-1;
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label.mdc-floating-label--float-above {
    @extend .body-1-default;
  }

  .mdc-text-field--focused .mdc-notched-outline__leading,
  .mdc-text-field--focused .mdc-notched-outline__notch,
  .mdc-text-field--focused .mdc-notched-outline__trailing {
    border-color: $primary-color;
  }

  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border-color: $outline-on-surface-light;
  }

  ::ng-deep .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color: $warn-color !important;
    opacity: 0.8 !important;
  }

  .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
    color: $warn-color !important;
  }

  .mat-icon {
    color: $medium-on-surface-light;
    padding: 0 10px !important;
  }

  .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: $warn-color !important;
  }
}

.custom-text-field-full-width {
  @extend .custom-text-field;
  width: 100%;
}



// autofill

.custom-text-autofill {
  @extend .custom-text-field;

  width: 100%;
  max-width: 300px;

  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border-color: transparent;
  }
}

// textarea

.custom-text-area {
  width: 100%;

  .mdc-text-field {
    padding: 0 !important;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field .mdc-notched-outline__notch {
    width: fit-content !important;
  }

  .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    max-width: none !important;
  }

  .mat-mdc-form-field-subscript-wrapper {
    height: 10px;
  }

  .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
    padding: 0 !important;
  }

  .mat-mdc-form-field-bottom-align::before {
    height: 10px;
  }

  .mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    transform: translateY(-8px) scale(1) !important;
    @extend .body-1-default;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 10px !important;
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
    @extend .body-1-regular-1;
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    @extend .body-1-regular-1;
  }

  .mdc-text-field--disabled .mdc-floating-label {
    @extend .body-1-regular-1;
  }

  .mdc-text-field--disabled .mdc-text-field__input {
    @extend .body-1-regular-1;
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label.mdc-floating-label--float-above {
    @extend .body-1-default;
  }

  .mdc-text-field .mdc-floating-label {
    top: 0;
  }

  .mdc-text-field--focused .mdc-notched-outline__leading,
  .mdc-text-field--focused .mdc-notched-outline__notch,
  .mdc-text-field--focused .mdc-notched-outline__trailing {
    border-color: $primary-color;
  }

  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border-color: $outline-on-surface-light;
  }

  .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
    color: $warn-color !important;
  }
}

// selection field

.custom-select-field {
  height: 33px;
  width: 200px;

  .mdc-text-field {
    //padding: 0 !important;
  }

  .mat-mdc-text-field-wrapper, .mat-mdc-form-field-infix, .mat-mdc-select-trigger {
    min-height: 33px;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding: 0 15px !important;
  }

  .mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    transform: translateY(-25px) scale(1) !important;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field .mdc-notched-outline__notch {
    width: fit-content !important;
    max-width: none !important;
  }

  .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    max-width: none !important;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    height: 33px !important;
    text-overflow: ellipsis;
  }

  .mat-mdc-form-field-subscript-wrapper {
    height: 10px;
  }

  .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
    padding: 0 !important;
  }

  .mat-mdc-form-field-bottom-align::before {
    height: 10px;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: calc(var(--mat-form-field-container-height)/3.2)!important;
  }

  .mdc-text-field--focused .mdc-notched-outline__leading,
  .mdc-text-field--focused .mdc-notched-outline__notch,
  .mdc-text-field--focused .mdc-notched-outline__trailing {
    border-color: $primary-color !important;
  }

  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border-color: $outline-on-surface-light !important;
  }

  .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: $warn-color !important;
  }

  .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
    color: $warn-color !important;
  }

  .mdc-text-field:not(.mat-mdc-select-disabled) .mdc-floating-label {
    @extend .body-1-regular-1;
  }

  .custom-select-field-value {
    @extend .body-1-default;
  }

  .mat-mdc-select-disabled .mdc-floating-label {
    @extend .body-1-regular-1;
  }

  .mat-mdc-select-disabled .mat-mdc-select-value {
    @extend .body-1-regular-1;
  }

  .mat-mdc-select-arrow {
    path {
      d: path('M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z') !important;
    }
  }
}

.custom-select-field-full-width {
  @extend .custom-select-field;
  width: 100%;

  .mat-mdc-select {
    border: 1px solid $outline-on-surface-light;
    padding: 5px 0;
    border-radius: 4px;
  }

  .mat-mdc-select:focus {
    outline: 2px solid $primary-color;
  }

  .custom-select-field-value {
    @extend .body-1-regular-1;
  }

  .mat-mdc-input-element:focus {
    outline: 2px solid $primary-color;
  }
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background-color: $primary-color !important;
}

.custom-select-field-value {
  .mat-mdc-select-trigger {
    padding: 0 22px;
  }
}

.mat-mdc-option {
  min-height: 40px !important;
}

.mdc-menu-surface {
  box-shadow: none !important;
}

.mdc-menu-surface.mat-mdc-select-panel {
  padding: 0 !important;
  margin-top: 5px !important;
  border: 1px solid $outline-on-surface-light;
  border-radius: 5px;
  background-color: $surface-light;
}

.cdk-overlay-pane {

}

.custom-select-field-option {
  @extend .body-1-regular-1;

  .mdc-list-item__primary-text:hover {
    font-weight: 500;
  }
}

.custom-select-field-section {
  @extend .body-1-default;

  .mat-icon {
    height: 18px;
    width: 18px;
    font-size: 18px;
    line-height: 18px;
  }
}

.custom-select-field-section:hover {
  @extend .body-1-primary;
}

.select-round-value {
  .mat-mdc-select-arrow {
    path {
      d: path('M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z') !important;
    }
  }
}

.select-round-panel.mdc-menu-surface {
  max-height: 90px;
}

.custom-select-field-borderless {
  @extend .custom-select-field;

  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border-color: transparent !important;
  }

}



// date picker

.custom-date-picker, .custom-date-picker-active {
  height: 33px;
  width: 190px;

  .mdc-text-field {
    padding: 0 !important;
    padding-left: 16px !important;
  }

  .mat-mdc-text-field-wrapper, .mat-mdc-form-field-infix, .mat-mdc-select-trigger {
    min-height: 33px;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    transform: translateY(-25px) scale(1) !important;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    height: 33px !important;
  }

  .mat-mdc-form-field-subscript-wrapper {
    height: 10px;
  }

  .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
    padding: 0 !important;
  }

  .mat-mdc-form-field-bottom-align::before {
    height: 10px;
  }

  .mdc-text-field--focused .mdc-notched-outline__leading,
  .mdc-text-field--focused .mdc-notched-outline__notch,
  .mdc-text-field--focused .mdc-notched-outline__trailing {
    border-color: $primary-color !important;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: $outline-on-surface-light;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
    border-color: $outline-on-surface-light;
  }

  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border-color: $outline-on-surface-light !important;
  }

  .mdc-text-field--focused .mdc-notched-outline__leading,
  .mdc-text-field--focused .mdc-notched-outline__notch,
  .mdc-text-field--focused .mdc-notched-outline__trailing {
    border-width: 2px;
  }

  .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
    color: $warn-color !important;
  }

  .mat-date-range-input-container {
    height: 33px;
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
    @extend .body-1-regular-1;
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    @extend .body-1-regular-1;
  }

  .mdc-text-field--disabled .mdc-floating-label {
    @extend .body-1-regular-1;
  }

  .mdc-text-field--disabled .mdc-text-field__input {
    @extend .body-1-regular-1;
  }

  .mat-mdc-icon-button {
    height: 33px;
    width: 33px;
    padding: 0;
  }

  .mat-mdc-icon-button:disabled {
    color: $disabled-dark;
  }
}

.custom-date-picker {
  .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
    @extend .body-1-regular-1;
  }
}

.custom-date-picker-active {
  .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
    @extend .body-1-default;
  }
}

.custom-date-picker-full-width {
  @extend .custom-date-picker;
  width: 100%;
}


// buttons

.dialog-button {
  width: 165px;
  height: 26px !important;
  background-color: $accent-color !important;
  border-radius: 5px;
}

.button-close-small {
  height: 14px !important;
  width: 14px !important;
  padding: 0 !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  align-self: end;

  .mat-icon {
    height: 12px;
    width: 12px;
    font-size: 12px;
    line-height: 12px;
    color: $medium-on-primary-dark;
  }

  .mat-mdc-button-touch-target {
    height: 100% !important;
    width: 100% !important;
  }
}

.button-close-top {
  height: 16px !important;
  width: 16px !important;
  padding: 0 !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  align-self: start;
  justify-self: end;

  .mat-icon {
    height: 12px;
    width: 12px;
    font-size: 12px;
    line-height: 12px;
    color: $medium-on-primary-dark;
  }

  .mat-mdc-button-touch-target {
    height: 100% !important;
    width: 100% !important;
  }
}

.button-primary {
  width: fit-content;
  height: 33px !important;
  border-radius: 16.5px !important;
  background-color: $primary-color !important;

  .button-text {
    @extend .body-1-active;
  }

  .mat-icon {
    color: $drawer-light;
    width: 1.5rem !important;
  }
}

.button-primary:disabled {
  background-color: $drawer-light !important;

  .button-text {
    color: $disabled-dark !important;
  }

  .mat-icon {
    color: $disabled-dark !important;
  }
}

.button-secondary {
  width: fit-content;
  height: 33px !important;
  border-radius: 16.5px !important;
  background-color: $surface-light !important;

  .button-text {
    @extend .body-1-default;
  }

  .mat-icon {
    color: $medium-on-primary-dark;
    width: 1.5rem !important;
  }
}

.button-secondary-outlined {
  border: 2px solid $primary-color !important;

  .button-text {
    color: $primary-color !important;
    @extend .body-1-default;
  }

  @extend .button-secondary;
}

.button-large {
  width: 150px !important;
}

.button-secondary:disabled {
  .button-text {
    color: $disabled-dark !important;
  }

  .mat-icon {
    color: $disabled-dark !important;
  }
}

.button-outline {
  width: fit-content;
  height: 33px !important;
  border-radius: 16.5px !important;
  background-color: $drawer-light !important;
  border: 1px solid $primary-color !important;

  .button-text {
    @extend .body-1-primary;
  }

  .mat-icon {
    color: $primary-color;
    width: 1.5rem !important;
  }
}

.button-outline:disabled {
  border: 1px solid $disabled-dark !important;

  .button-text {
    color: $disabled-dark !important;
  }

  .mat-icon {
    color: $disabled-dark !important;
  }
}

.button-outline-white {
  @extend .button-outline;
  border: 1px solid $primary-color !important;
  background-color: $drawer-light !important;
}

.button-action {
  width: fit-content;
  min-width: 40px !important;
  height: 18px !important;
  border-radius: 5px !important;
  background-color: $accent-color !important;
  padding: 0 7px !important;

  .button-text {
    @extend .footer-primary;
  }

  .mat-icon {
    color: $primary-color;
  }
}

.button-action:disabled {
  background-color: $drawer-light !important;

  .button-text {
    @extend .footer-default
  }

  .mat-icon {
    color: $disabled-dark !important;
  }
}

.button-action-cancel {
  @extend .button-action;
  background-color: $drawer-light !important;

  .button-text {
    color: $disabled-dark !important;
  }

  .mat-icon {
    color: $disabled-dark !important;
  }
}

// info tabs

.mat-card-image-container {
  width: 100%;
  border-radius: 5px;
  height: 260px;
  position: relative;
}

.custom-info-tab {

  .mat-mdc-tab.mdc-tab {
    height: 18px !important;
    min-width: 41px !important;
    border-radius: 5px;
    background-color: $drawer-light;
  }

  .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active {
    border: 1px solid $primary-color;
  }

  .mat-mdc-tab .mdc-tab__text-label {
    @extend .footer-regular;
  }

  .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    @extend .footer-primary;
  }

  .mdc-tab-indicator {
    display: none;
  }

  .mat-mdc-tab {
    padding: 0;
  }

  .mat-mdc-tab-list {
    flex-grow: 0;
  }
}

// table

.custom-table {
  background-color: transparent !important;

  .custom-table-header {
    background-color: $primary-color;
    height: 22.5px !important;
    border-radius: 15px;
    vertical-align: baseline;
  }

  th {
    @extend .body-1-active;
  }

  th:first-of-type {
    border-radius: 0 0 0 12px;
  }

  th:last-of-type {
    border-radius: 0 0 12px 0;
  }

  .custom-table-row {
    cursor: pointer;
    height: 41px !important;

    .custom-table-cell {
      @extend .body-1-regular-1;
    }
  }

  .mat-column-select {
    flex: 0 0 30px !important;
    padding: 0 !important;
  }

  .custom-table-row:hover {
    background-color: $accent-color !important;

    .custom-table-cell {
      @extend .body-1-primary;
    }
  }

  .custom-table-label {
    @extend .subheading-active;
    border-radius: 12px 12px 0 0 !important;
    border-bottom-width: 0;
    vertical-align: bottom;
  }
}

.table-card {
  width: 100%;
  background-color: $drawer-light;
  border-radius: 15px;
  padding: 20px;
}

//

.mat-mdc-checkbox.mat-primary .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: $drawer-light !important;
}

.mat-mdc-checkbox.mat-accent .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: $primary-color !important;
}

//

.custom-date-button-toggle {
  height: 33px;
  width: auto;
  top: 1px;

  .mat-button-toggle-button {
    height: 33px;
  }

  .mat-button-toggle {
    flex-grow: 0.33;
    background-color: transparent;
    border-left: none !important;
  }

  .mat-button-toggle-appearance-standard {
    @extend .body-1-regular-1;
  }

  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    @extend .body-1-default;
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 33px;
  }

  .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
    background-color: transparent;
    opacity: 0.6;
  }
}

.custom-borderless-button-toggle {
  width: auto;
  top: 1px;
  border: none !important;

  .mat-button-toggle-button {
    height: 33px;
  }

  .mat-button-toggle {
    flex-grow: 0.33;
    background-color: transparent;
    border: none !important;
  }

  .mat-button-toggle-appearance-standard {
    @extend .body-1-regular-1;
  }

  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    @extend .body-1-default;
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 33px;
  }

  .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
    background-color: transparent;
    opacity: 0.6;
  }
}


// calendar

.mat-datepicker-content {
  box-shadow: none !important;
  border: 1px solid $outline-on-surface-light;
  background-color: $surface-light !important;
}

.mat-calendar-body {
  @extend .body-1-default;
}

.mat-calendar-body-label, .mat-calendar-table-header {
  @extend .body-1-regular-2;
}

.mat-datepicker-toggle-active {
  color: $primary-color !important;
}

.mat-calendar-body-selected {
  background-color: $primary-color !important;
}

.mat-calendar-body-in-range::before {
  background-color: $accent-color !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: $outline-on-surface-light !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: $accent-color !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):focus > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: $accent-color !important;
}

.mat-calendar-previous-button::after, .mat-calendar-next-button::after {
  margin: 20px !important;
}

.mat-datepicker-content .mat-calendar {
  height: 374px !important;
}

// paginator

.custom-paginator {
  @extend .body-1-default;

  .mat-mdc-text-field-wrapper, .mat-mdc-form-field-flex, .mat-mdc-select {
    height: 24px;
  }

  .mat-mdc-select-value {
    @extend .body-2-default;
  }

  .mdc-text-field--outlined {
    padding: 0 10px;
  }

  .mat-mdc-paginator-page-size-select {
    width: 55px;
    min-width: 55px;
  }

  .mat-mdc-form-field-infix {
    padding: 0 !important;
    min-height: 24px !important;
  }

  .mdc-text-field--focused .mdc-notched-outline__leading,
  .mdc-text-field--focused .mdc-notched-outline__notch,
  .mdc-text-field--focused .mdc-notched-outline__trailing {
    border-color: $primary-color;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: $outline-on-surface-light;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
    border-color: $outline-on-surface-light;
  }

  .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
    color: $warn-color !important;
  }

}

.info-card {
  width: 100%;
  background-color: $drawer-light;
  border-radius: 12px;
  padding: 10px 20px 20px 20px;
}

// half-and-half table

.half-table {
  min-width: 50% !important;

  th:first-of-type {
    border-radius: 0 0 0 12px;
  }

  th:last-of-type {
    border-radius: 0 0 12px 0;
  }

  .mat-mdc-checkbox .mdc-form-field {
    height: 32px;
  }
}

.half-table:first-of-type {
  th:last-of-type {
    border-radius: 0;
  }

  .custom-table-label {
    border-radius: 12px 0 0 0 !important;
  }

  .selected-row {
    background-color: $accent-color !important;

    .custom-table-cell {
      @extend .body-1-primary;
    }
  }
}

.half-table:last-of-type {
  th:first-of-type {
    border-radius: 0;
  }

  .custom-table-label {
    border-radius: 0 12px 0 0 !important;
  }

  .custom-table-row {
    background-color: $accent-color !important;
  }

  .custom-table-cell {
    @extend .body-1-primary;
  }
}

// edit/ add to table

.edit-table-row {
  background-color: $accent-color;
  border-radius: 12px;
  height: 70px;
  padding: 0 15px;
}

.edit-row-button {
  height: 60px;
}

//

.align-right {
  text-align: end;
}

// chips

.success-chip, .disabled-chip, .error-chip, .warn-chip {
  width: 70px;
  height: 17px;
  background-color: $panel-grey;
  border-radius: 12px;
  padding-left: 2px;

  .mat-icon {
    height: 13px;
    width: 13px;
    font-size: 13px;
    line-height: 13px;
  }
}

.success-chip .mat-icon {
  color: $success-green;
}

.disabled-chip .mat-icon {
  color: $disabled-dark;
}

.warn-chip .mat-icon {
  color: $warn-orange;
}

.error-chip .mat-icon {
  color: $warn-color;
}

.dashboard-card {
  background-color: $surface-light;
  border-radius: 12px;
  padding: 10px;
  min-height: 114px;
}

// dashboard

.dashboard-page {
  background-color: $drawer-light;
}

.dashboard-icon {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  border: 1px solid $medium-on-surface-light;
  display: flex;
  justify-content: center;
  align-items: center;

  .mat-icon {
    height: 20px;
    width: 20px;
    font-size: 20px;
    line-height: 20px;
    font-weight: 100;
    color: $medium-on-surface-light;;
  }
}

.dashboard-table {
  background-color: transparent !important;
  min-width: 50% !important;

  .custom-table-header {
    background-color: $drawer-light;
    height: 58px !important;
  }

  .mdc-data-table__header-cell {
    border-bottom-color: $border-grey !important;
  }

  th {
    @extend .body-1-default;
  }

  .custom-table-row {
    cursor: pointer;
    height: 58px !important;

    .custom-table-cell {
      @extend .body-1-regular-2;
      border-bottom-color: $border-grey !important;
    }
  }

  .mat-icon {
    height: 30px;
    width: 30px;
    font-size: 30px;
    line-height: 30px;
    color: $medium-on-primary-dark;
  }
}

.sidenav-section {
  background-color: $drawer-light;

  .mat-expansion-panel-body {
    padding: 0 0 15px 0 !important;
  }
}

.section-divider {
  padding-top: 30px;
  background-color: $surface-light;
  width: 100%;
}

.owner-logo {
  height: 40px !important;
  width: 40px !important;
  border-radius: 20px;
}

// progress bar

.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: $accent-color !important;
}

// selection-list

.custom-selection-list {
  padding: 0 !important;

  .mdc-list-item.mdc-list-item--with-one-line,
  .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line,
  .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line,
  .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
    height: 42px;
    border-radius: 4px;
    background-color: $drawer-light;
  }

  .mdc-list-item__primary-text {
    @extend .heading-regular;
  }

  .mat-mdc-list-option .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle,
  .mat-mdc-list-option .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
    border-color: $primary-color !important;
  }

  .mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
  .mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
  .mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-color: $primary-color !important;
    background-color: $primary-color !important;
  }
}

.custom-selection-list-fill {
  @extend .custom-selection-list;

  .mdc-list-item.mdc-list-item--with-one-line,
  .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line,
  .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line,
  .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
    background-color: $accent-color;
  }
}

.custom-selection-list-outline {
  @extend .custom-selection-list;

  .mdc-list-item.mdc-list-item--with-one-line,
  .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line,
  .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line,
  .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
    border: 1px solid $outline-on-surface-light;
    background-color: transparent;
  }

  .mdc-list-item--disabled .mdc-list-item__primary-text,
  .mdc-list-item--disabled .mdc-list-item__secondary-text,
  .mdc-list-item--disabled .mdc-list-item__overline-text {
    opacity: 1;
  }

  .mdc-list-item--disabled .mdc-radio,
  .mdc-list-item--disabled .mdc-checkbox {
    opacity: 1;
  }

  .mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control[disabled]:checked~.mdc-checkbox__background,
  .mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control[disabled]:indeterminate~.mdc-checkbox__background,
  .mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true][disabled]~.mdc-checkbox__background {
    background-color: $primary-color;
  }
}

.form-input-field {
  height: 42px !important;

  .mat-mdc-text-field-wrapper, .mat-mdc-form-field-infix {
    min-height: 42px !important;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    height: 42px !important;
    text-overflow: ellipsis;
  }

  .mat-date-range-input-container {
    height: 42px !important;
  }
}

.drawer-button {
  height: 32px !important;
  width: 32px !important;
  display: flex;
  padding: 6px !important;
  background-color: #F2F2F2 !important;

  .mat-icon {
    color: $medium-on-surface-light;
    height: 20px;
    width: 20px;
    font-size: 20px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ::ng-deep svg {
    height: 32px;
    width: 32px;
  }
}

.listing-drawer-container {
  border: 1px solid $border-grey !important;
  border-radius: 7px 0 0 7px;

  .mat-drawer-inner-container {
    overflow-y: scroll;
  }
}

.search-icon-suffix {
  height: 16px !important;
  width: 16px !important;
  font-size: 16px;
  line-height: 16px;

  color: $disabled-dark !important;
}

//sidenav container

.sidenav-container {
  height: $min-container-height-mobile;
  flex-grow: 1;
  background-color: $surface-light !important;
}

.sidenav-container-no-toolbar {
  height: $min-container-height-no-toolbar-mobile;
}

.sidenav-right {
  width: 300px;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  border-left: 1px solid $panel-grey !important;
  background-color: $surface-light !important;
}

.sidenav-main-content, .sidenav-right-content {
  /* Hide the scrollbar */
  &::-webkit-scrollbar {
    width: 0.5em;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.form-table {
  background-color: transparent !important;

  .custom-table-header {
    background-color: $drawer-light;
    height: 40px !important;
  }

  .mdc-data-table__header-cell {
    border-bottom-color: $border-grey !important;
  }

  th {
    @extend .body-1-default;
  }

  .custom-table-row {
    cursor: pointer;
    height: 58px !important;

    .custom-table-cell {
      @extend .body-1-regular-2;
      border-bottom-color: $border-grey !important;
    }
  }
}

.form-container {
  border-radius: 12px;
  background-color: $drawer-light;
}

.form-accordion {

  .mat-expansion-panel {
    border: none;
    box-shadow: none !important;
    border-radius: 12px !important;
  }

  .mat-expansion-panel-header {
    padding-top: 10px;
    align-items: flex-start;
    height: 50px;

    .panel-arrow {
      transform: rotate(270deg);
      transition: transform 0.3s ease-in-out;
    }
  }

  .mat-expansion-panel-header.mat-expanded {
    height: 50px;

    .panel-arrow {
      transform: rotate(90deg);
      transition: transform 0.3s ease-in-out;
    }
  }

  .mat-expansion-panel-spacing {
    margin-bottom: 0;
  }
}

.square-icon {
  height: 25px;
  width: 25px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  .mat-icon {
    height: 20px;
    width: 20px;
    font-size: 20px;
    color: $medium-on-primary-dark;
  }
}

.primary-color {
  color: $primary-color;
}

.primary-color-lighter {
  color: lighten(primary, 40%);
}

.accent-color {
  color: $accent-color;
}

.warn-orange-color {
  color: $warn-orange;
}

.primary-bg-0 {
  background-color: lighten(primary, 0%);
}

.primary-bg-10 {
  background-color: lighten(primary, 10%);
}

.primary-bg-20 {
  background-color: lighten(primary, 20%);
}

.primary-bg-30 {
  background-color: lighten(primary, 30%);
}

.primary-bg-40 {
  background-color: lighten(primary, 40%);
}

.primary-bg-50 {
  background-color: lighten(primary, 50%);
}

.hide-scroll-bar {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.mat-mdc-input-element {
  @extend .body-1-regular-1;

  width: 100%;
  height: 42px !important;
  background-color: transparent;
  border: 1px solid $outline-on-surface-light;
  border-radius: 4px;
  text-overflow: ellipsis;
  text-indent: 20px;
}

.form-field-padding-wrapper {
  padding-right: 1.5rem;
}


app-application-form-row, app-application-form-row-label{
  @extend .full-width;
}

.questionnaire-wrapper {
  .mat-vertical-content {
    padding: 20px 0 0;
  }

  .mat-vertical-content-container {
    margin: 0;
  }
}


// stepper
.mat-step-icon {
  background-color: $accent-color;
}

.mat-step-icon-selected {
  background-color: $primary-color;
}

.mat-step-icon-state-number {
  color: $drawer-light;
}

.mat-vertical-stepper-header {
  padding: 5px 0;
}


//snackbar
.mat-mdc-snack-bar-action {
  @extend .body-1-primary;
}

.mat-mdc-snack-bar-label {
  @extend .body-1-regular-1;
  font-size: 12px!important;
}

.mdc-snackbar__surface {
    background: $accent-color !important;
}

.mdc-snackbar {
  padding-top: 65px;
  padding-right: 15px;
}

@media (min-width: 600px) {
  .page-padding {
    padding-left: 40px;
    padding-right: 30px;
  }

  .sidenav-container {
    height: $min-container-height;
  }

  .sidenav-container-no-toolbar {
    height: $min-container-height-no-toolbar;
  }
}

.otp-input-element {
  border-bottom: 2px solid $primary-color !important;
  margin-left: 12px;
  margin-right: 12px;
  font-size: 22px !important;
}

.otp-input-element-disabled {
  border-bottom: 2px solid $disabled-dark !important;
  margin-left: 12px;
  margin-right: 12px;
  font-size: 22px !important;
  pointer-events: none;
}
