@use '@angular/material' as mat with (
  $theme-ignore-duplication-warnings: true
);

@include mat.core();

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

$custom_palette: (
  50: #F9F9F9,
  100: #C6C6C6,
  200: #ABABAB,
  300: #777777,
  400: #5E5E5E,
  500: #525252,
  600: #3B3B3B,
  700: #303030,
  800: #262626,
  900: #1B1B1B,
  A100: #F9F9F9,
  A200: #C6C6C6,
  A400: #777777,
  A700: #303030,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

@mixin initLibTheme($custom-theme, $default-theme, $typography) {
  @include initLibThemeColors($custom-theme, $default-theme, $typography);

  @include mat.all-component-themes($custom-theme);
  @include mat.all-component-typographies($typography);
  @include mat.core-theme($custom-theme);
  @include mat.button-theme($custom-theme);
  @include mat.checkbox-theme($custom-theme);
  @include mat.menu-theme($custom-theme);
  @include mat.expansion-theme($custom-theme);
  @include mat.table-theme($custom-theme);
  @include mat.dialog-theme($custom-theme);
  @include mat.input-theme($custom-theme);
  @include mat.toolbar-theme($custom-theme);
  @include mat.card-theme($custom-theme);
  @include mat.sidenav-theme($custom-theme);
  @include mat.divider-theme($custom-theme);
  @include mat.list-theme($default-theme);
  @include mat.radio-theme($custom-theme);
  @include mat.select-theme($custom-theme);
  @include mat.snack-bar-theme($custom-theme);
  @include mat.tabs-theme($custom-theme);
  @include mat.chips-theme($custom-theme);
  @include mat.paginator-theme($custom-theme);
  @include mat.slide-toggle-theme($custom-theme);
  @include mat.progress-bar-theme($custom-theme);
  @include mat.tooltip-theme($custom-theme);
  @include mat.datepicker-color($default-theme);
  @include mat.autocomplete-theme($custom-theme);
  @include mat.progress-spinner-theme($custom-theme);
  @include mat.badge-theme($custom-theme);
  @include mat.option-theme($custom-theme);
  @include mat.button-toggle-theme($custom-theme);
}

@mixin initLibThemeColors($lib-custom-theme, $lib-default-theme, $lib-typography) {
  $default-theme: $lib-default-theme;
  $theme: $lib-custom-theme;
  $typography: $lib-typography;

  :root {
    --primary-color: #{mat.get-theme-color($theme, primary, default)};
    --primary-contrast-color: #{mat.get-theme-color($theme, primary, default-contrast)};
    --secondary-color: #{mat.get-theme-color($theme, accent, default)};
  }
}

$typography: mat.define-typography-config(
  $headline-1: mat.define-typography-level(112px, 112px, 300, 'Roboto', -0.0134em),
  $headline-2: mat.define-typography-level(56px, 56px, 400, 'Roboto', -0.0089em),
  $headline-3: mat.define-typography-level(45px, 48px, 400, 'Roboto', 0.0000em),
  $headline-4: mat.define-typography-level(34px, 40px, 400, 'Roboto', 0.0074em),
  $headline-5: mat.define-typography-level(24px, 32px, 400, 'Roboto', 0.0000em),
  $headline-6: mat.define-typography-level(20px, 32px, 500, 'Roboto', 0.0075em),
  $subtitle-2: mat.define-typography-level(16px, 28px, 400, 'Roboto', 0.0094em),
  $subtitle-1: mat.define-typography-level(15px, 24px, 500, 'Roboto', 0.0067em),
  $body-2: mat.define-typography-level(14px, 24px, 500, 'Roboto', 0.0179em),
  $body-1: mat.define-typography-level(14px, 20px, 400, 'Roboto', 0.0179em),
  $button: mat.define-typography-level(14px, 14px, 500, 'Roboto', 0.0893em),
  $caption: mat.define-typography-level(12px, 20px, 400, 'Roboto', 0.0333em),
);

// Define a dark theme
$light-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($custom-palette),
    accent: mat.define-palette($custom-palette),
  ),
  typography: $typography,
  density: 1,
));

// Apply the dark theme by default
@include mat.core-theme($light-theme);
@include mat.button-theme($light-theme);
@include initLibTheme($light-theme, $light-theme, $typography);


