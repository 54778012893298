@use '../../node_modules/@angular/material/index' as mat;

$disabled-dark: rgba(0, 0, 0, 0.38);
$disabled-light: rgba(255, 255, 255, 0.38);
$slightly-grey: rgba(0, 0, 0, 0.01);

$medium-on-surface-light: rgba(#000000, 0.6);
$medium-on-primary-dark: rgba(#000000, 0.74);
$high-on-primary-dark: rgba(#000000, 1);

$heading-grey-dark: #202020;

$surface-dark: #121212;
$surface-light: #F8F8FA;
$panel-grey: #F4F4F4;

$outline-on-surface-dark: #121212;
$outline-on-surface-light: rgba(#000000, 0.12);

$active-text: #FFFFFF;

$drawer-light: #FFFFFF;
$border-grey: #F0F0F0;
$icon-grey: #ACACAC;

$warn-orange: #FF9E03;
$success-green: #00C480;
$info-blue: #15CCF2;
$logo-grey: #E6E6E6;

:root {
  --primary-color: 0, 82, 126;
  --primary-color-h: 201;
  --primary-color-s: 100%;
  --primary-color-l: 24.7%;
  --secondary-color: #eceeed;
  --warn-color: #B00020;
}

$primary-color: var(--primary-color);
$accent-color: var(--secondary-color);
$warn-color: var(--warn-color);

@function lighten($shadeCode, $amount) {
  @return hsl(var(--#{$shadeCode}-color-h), var(--#{$shadeCode}-color-s), calc(#{var(--#{$shadeCode}-color-l)} + #{$amount}));
}

@function darken($shadeCode, $amount) {
  @return hsl(var(--#{$shadeCode}-color-h), var(--#{$shadeCode}-color-s), calc(#{var(--#{$shadeCode}-color-l)} - #{$amount}));
}
