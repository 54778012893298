@use '@angular/material' as mat;
@use 'sass:map';
@import "colors";

$title: mat.define-typography-level(24px, 20px, 500, Roboto, 0.528px);
$subtitle: mat.define-typography-level(18px, 20px, 500, Roboto, 0.252px);
$heading: mat.define-typography-level(14px, 20px, 400, Roboto, 0.252px);
$body-1: mat.define-typography-level(12px, 16px, 500, Roboto, 0.396px);
$subheading: mat.define-typography-level(10px, 16px, 400, Roboto, 1.5px);
$body-2: mat.define-typography-level(10px, 13px, 300, Roboto, 0px);
$body-3: mat.define-typography-level(8px, 16px, 400, Roboto, 1.2px);
$footer: mat.define-typography-level(8px, 10px, 500, Roboto, 0px);
$link: mat.define-typography-level(8px, 10px, 300, Roboto, 0px);
$body-4: mat.define-typography-level(6px, 8px, 300, Roboto, 0px);
$body-5: mat.define-typography-level(6px, 16px, 400, Roboto, 0.9px);

$soko-typography: mat.define-typography-config(
  $font-family: Roboto,
);

$soko-typography: map.merge(
    $soko-typography,
    (
      title: $title,
      subtitle: $subtitle,
      heading: $heading,
      body-1: $body-1,
      subheading: $subheading,
      body-2: $body-2,
      body-3: $body-3,
      footer: $footer,
      link: $link,
      body-4: $body-4,
      body-5: $body-5
    )
);

// all font classes

.title-default {
  @include mat.typography-level($soko-typography, 'title');
  color: $high-on-primary-dark !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 20px !important;
  letter-spacing: 0.528px !important;
}

.title-regular {
  @include mat.typography-level($soko-typography, 'title');
  color: $high-on-primary-dark !important;
  font-weight: 400 !important;
  font-size: 22px !important;
  line-height: 20px !important;
  letter-spacing: 0.528px !important;
}

.title-large {
  @include mat.typography-level($soko-typography, 'title');
  color: $high-on-primary-dark !important;
  font-weight: 500 !important;
  font-size: 60px !important;
  line-height: 60px !important;
  letter-spacing: 1.98px !important;
}

.title-small {
  @include mat.typography-level($soko-typography, 'title');
  color: $high-on-primary-dark !important;
  font-weight: 400 !important;
  font-size: 22px !important;
  line-height: 16px !important;
  letter-spacing: 0.726px !important;
}

.subtitle-default {
  @include mat.typography-level($soko-typography, 'subtitle');
  color: $high-on-primary-dark !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 20px !important;
  letter-spacing: 0.252px !important;
}

.subtitle-regular {
  @include mat.typography-level($soko-typography, 'subtitle');
  color: $high-on-primary-dark !important;
  font-weight: 300 !important;
  font-size: 18px !important;
  line-height: 20px !important;
  letter-spacing: 0.252px !important;
}

.subtitle-active {
  @include mat.typography-level($soko-typography, 'subtitle');
  color: $active-text !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 20px !important;
  letter-spacing: 0.252px !important;
}

.heading-default {
  @include mat.typography-level($soko-typography, 'heading');
  color: $medium-on-primary-dark !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: 0.252px !important;
}

.heading-bold {
  @include mat.typography-level($soko-typography, 'heading');
  color: $heading-grey-dark !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: 0.252px !important;
}

.heading-regular {
  @include mat.typography-level($soko-typography, 'heading');
  color: $medium-on-primary-dark !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: 0.252px !important;
}

.heading-primary-1 {
  @include mat.typography-level($soko-typography, 'heading');
  color: $primary-color !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: 0.252px !important;
}

.heading-primary-2 {
  @include mat.typography-level($soko-typography, 'heading');
  color: $primary-color !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: 0.252px !important;
}

.heading-secondary {
  @include mat.typography-level($soko-typography, 'heading');
  color: $accent-color !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: 0.252px !important;
}

.body-1-default {
  @include mat.typography-level($soko-typography, 'body-1');
  color: $high-on-primary-dark !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.396px !important;
}

.body-1-active {
  @include mat.typography-level($soko-typography, 'body-1');
  color: $active-text !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.396px !important;
}

.body-1-regular-1 {
  @include mat.typography-level($soko-typography, 'body-1');
  color: $high-on-primary-dark !important;
  font-weight: 300 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.396px !important;
}

.body-1-regular-2 {
  @include mat.typography-level($soko-typography, 'body-1');
  color: $medium-on-surface-light !important;
  font-weight: 300 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.396px !important;
}

.body-1-regular-active {
  @include mat.typography-level($soko-typography, 'body-1');
  color: $drawer-light !important;
  font-weight: 300 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.396px !important;
}

.body-1-primary {
  @include mat.typography-level($soko-typography, 'body-1');
  color: $primary-color !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.396px !important;
}

.body-1-error {
  @include mat.typography-level($soko-typography, 'body-1');
  color: $warn-color !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.396px !important;
}

.subheading-default {
  @include mat.typography-level($soko-typography, 'subheading');
  color: $medium-on-surface-light !important;
  text-transform: uppercase !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 16px !important;
  letter-spacing: 1.5px !important;
}

.subheading-active {
  @include mat.typography-level($soko-typography, 'subheading');
  color: $active-text !important;
  text-transform: uppercase !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 16px !important;
  letter-spacing: 1.5px !important;
}

.body-2-default {
  @include mat.typography-level($soko-typography, 'body-2');
  color: $medium-on-surface-light !important;
  font-weight: 300 !important;
  font-size: 10px !important;
  line-height: 13px !important;
  letter-spacing: 0 !important;
}

.body-3-default {
  @include mat.typography-level($soko-typography, 'body-3');
  color: $medium-on-surface-light !important;
  text-transform: uppercase !important;
  font-weight: 400 !important;
  font-size: 8px !important;
  line-height: 16px !important;
  letter-spacing: 1.2px !important;
}

.footer-default {
  @include mat.typography-level($soko-typography, 'footer');
  color: $medium-on-primary-dark !important;
  font-weight: 500 !important;
  font-size: 8px !important;
  line-height: 10px !important;
  letter-spacing: 0 !important;
}

.footer-active {
  @include mat.typography-level($soko-typography, 'footer');
  color: $active-text !important;
  font-weight: 500 !important;
  font-size: 8px !important;
  line-height: 10px !important;
  letter-spacing: 0 !important;
}

.footer-regular {
  @include mat.typography-level($soko-typography, 'footer');
  color: $medium-on-surface-light !important;
  font-weight: 300 !important;
  font-size: 8px !important;
  line-height: 10px !important;
  letter-spacing: 0 !important;
}

.footer-primary {
  @include mat.typography-level($soko-typography, 'footer');
  color: $primary-color !important;
  font-weight: 500 !important;
  font-size: 8px !important;
  line-height: 10px !important;
  letter-spacing: 0 !important;
}

.link-default {
  @include mat.typography-level($soko-typography, 'link');
  color: $primary-color !important;
  text-decoration: none !important;
  font-weight: 300 !important;
  font-size: 8px !important;
  line-height: 10px !important;
  letter-spacing: 0 !important;
}

.link-error {
  @include mat.typography-level($soko-typography, 'link');
  color: $warn-color !important;
  text-decoration: none !important;
  font-weight: 500 !important;
  font-size: 8px !important;
  line-height: 10px !important;
  letter-spacing: 0 !important;
}

.body-4-default {
  @include mat.typography-level($soko-typography, 'body-4');
  color: $medium-on-primary-dark !important;
  font-weight: 300 !important;
  font-size: 6px !important;
  line-height: 8px !important;
  letter-spacing: 0 !important;
}

.body-4-active {
  @include mat.typography-level($soko-typography, 'body-4');
  color: $active-text !important;
  font-weight: 300 !important;
  font-size: 6px !important;
  line-height: 8px !important;
  letter-spacing: 0 !important;
}

.body-4-primary {
  @include mat.typography-level($soko-typography, 'body-4');
  color: $primary-color !important;
  font-weight: 300 !important;
  font-size: 6px !important;
  line-height: 8px !important;
  letter-spacing: 0 !important;
}

.body-5-default {
  @include mat.typography-level($soko-typography, 'body-5');
  color: $medium-on-surface-light !important;
  text-transform: uppercase !important;
  font-weight: 400 !important;
  font-size: 6px !important;
  line-height: 16px !important;
  letter-spacing: 0.9px !important;
}

.body-5-active {
  @include mat.typography-level($soko-typography, 'body-5');
  text-transform: uppercase !important;
  color: $active-text !important;
  font-weight: 400 !important;
  font-size: 6px !important;
  line-height: 16px !important;
  letter-spacing: 0.9px !important;
}
